/* eslint-disable no-nested-ternary */
/* eslint-disable no-promise-executor-return */
import React, { useEffect, useState, useContext, useMemo, useRef } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import numeral from 'numeral'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
// import Carousel from 'react-bootstrap/Carousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSmileWink } from '@fortawesome/free-regular-svg-icons'
import {
  // faCaretLeft,
  faCheckCircle,
  // faCaretRight,
  faQuestionCircle,
  faPlusCircle,
  faMinusCircle,
} from '@fortawesome/free-solid-svg-icons'
import {
  Dropdown,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import { NumericFormat } from 'react-number-format'
import { AuthContext, ToastContext } from '../components/ContextProvider'
import {
  // accFacebook,
  accTwitter,
  // accTypeXTwitter,
  accGoogle,
  accTaboola,
  accYahoo,
  accLinkedin,
  accLine,
  accCriteo,
  accTTD,
  accMeta,
  accTypeDcard,
  accTypeTiktok,
  accTypeNarwhal,
} from '../assets'
import {
  FormDialog,
  InfoDialog,
  ShowcaseCard,
  ConfirmForm,
} from '../components'
import apiService from '../services/apiService'
import {
  getForm,
  getFixedForm,
  // getOriginIcon,
  cpms,
} from '../services/lucaFunctions'

function Question(props) {
  const { setting } = props
  const { remark } = setting

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={
        <Tooltip
          className="quesTip"
          style={{
            zIndex: '9999',
          }}
        >
          ＊{remark}
        </Tooltip>
      }
    >
      <div className="my-auto d-flex ms-2 h-100">
        <FontAwesomeIcon
          className="fs-7 btn-lucaIcon my-auto"
          style={{ right: '10', top: '50', bottom: '50' }}
          icon={faQuestionCircle}
        />
      </div>
    </OverlayTrigger>
  )
}

function Carousel(props) {
  const { setting } = props
  const { items } = setting
  // const [mousedown, setmousedown] = useState(false)
  // const [moving, setmoving] = useState(false)
  // const [page, setpage] = useState(0)
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (moving) {
  //       if (mousedown) {
  //         if (moving === 'left') setpage(page === items.length ? 0 : page + 1)
  //         if (moving === 'right') setpage(page === 0 ? items.length : page - 1)
  //       } else {
  //         setmoving(false)
  //       }
  //     }
  //   }, 500)
  // }, [moving, mousedown, page])

  // useEffect(() => setpage(0), [items])

  return (
    <div
      className="d-flex h-100 w-100"
      // onMouseLeave={() => {
      //   if (mousedown) setmousedown(false)
      // }}
      // onMouseUp={() => {
      //   if (mousedown) setmousedown(false)
      // }}
      aria-hidden
    >
      {/* <div
        className="d-flex h-100"
        style={{
          width: '2.5%',
          zIndex: '1',
        }}
      >
        <FontAwesomeIcon
          icon={faCaretLeft}
          className="m-auto fs-1 px-0 text-light"
          onMouseDown={() => {
            if (items.length <= 4) return
            setpage(page === 0 ? items.length : page - 1)
            setmousedown(true)
            setmoving('right')
          }}
          style={{
            cursor: items.length <= 4 ? 'not-allowed' : 'pointer',
          }}
        />
      </div> */}
      <Row className="d-flex h-100 position-relative overflow-auto d-flex flex-wrap">
        {/* <div
          className="position-absolute d-flex mt-auto px-2 justify-content-center"
          style={{
            top: '15px',
          }}
        >
          {items.map(({ title, method }, i) => (
            <span
              key={i}
              className="d-flex px-2"
              title={title}
              style={{
                opacity: title === 'X' ? '0.7' : '1',
                cursor: 'pointer',
              }}
              onClick={async () => {
                setpage(i)
                const delay = (ms) =>
                  new Promise((resolve) => setTimeout(resolve, ms))
                await delay(1000)
                method()
              }}
              aria-hidden
            >
              {getOriginIcon(title, '22px', 'auto')}
            </span>
          ))}
        </div> */}
        {items.map((item, i) => (
          <Col
            key={i}
            xs={3}
            className="openAcc p-2"
            style={{
              height: '48%',
            }}
            aria-hidden
          >
            <ShowcaseCard setting={item} />
          </Col>
        ))}
      </Row>
      {/* <div
        className="d-flex h-100"
        style={{
          width: '2.5%',
          zIndex: '1',
        }}
      >
        <FontAwesomeIcon
          icon={faCaretRight}
          className="m-auto fs-1 px-0 text-light"
          onMouseDown={() => {
            if (items.length <= 4) return
            setpage(page === items.length ? 0 : page + 1)
            setmousedown(true)
            setmoving('left')
          }}
          style={{
            cursor: items.length <= 4 ? 'not-allowed' : 'pointer',
          }}
        />
      </div> */}
    </div>
  )
}

Carousel.propTypes = {
  setting: PropTypes.shape().isRequired,
}

function Narwhal({ setting }) {
  const { show, handleClose } = setting
  const auth = useContext(AuthContext)

  const [doublechecking, setdoublechecking] = useState(false)
  const [strict, setstrict] = useState(true)
  const basic = {
    title: '進案資料(※Ragic及ARGO登記資料，請務必填妥)',
    columns: [
      {
        name: '',
        data: 'Ragic委刊單號：',
        color: 'red',
        xs: 2,
        position: 'start',
      },
      {
        name: 'Ragic委刊單號：',
        data: '',
        xs: 4,
        type: 'text',
      },
      {
        name: '',
        data: 'ARGO客戶代號：',
        color: 'red',
        xs: 2,
      },
      {
        name: 'ARGO客戶代號：',
        data: '',
        xs: 4,
        position: 'end',
        type: 'text',
      },
      {
        name: '',
        data: '客戶名稱(全名)：',
        color: 'red',
        xs: 2,
        position: 'start',
      },
      {
        name: '客戶名稱(全名)：',
        data: '',
        xs: 4,
        type: 'text',
      },
      {
        name: '',
        data: '活動名稱：',
        color: 'red',
        xs: 2,
      },
      {
        name: '活動名稱：',
        data: '',
        xs: 4,
        position: 'end',
        type: 'text',
      },
      {
        name: '',
        data: '客戶分類：',
        color: 'red',
        xs: 2,
        position: 'start',
      },
      {
        name: '客戶分類：',
        data: '',
        type: 'select',
        options: [
          { label: '新客新單', value: '新客新單' },
          { label: '舊客新單', value: '舊客新單' },
          { label: '舊客續約', value: '舊客續約' },
        ],
        xs: 4,
      },
      {
        name: '',
        data: '產業類別：',
        color: 'red',
        xs: 2,
      },
      {
        name: '產業類別：',
        data: '',
        type: 'select',
        options: [
          { label: '01.生活消費品', value: '01.生活消費品' },
          { label: '02.交通', value: '02.交通' },
          { label: '03.服飾時尚', value: '03.服飾時尚' },
          { label: '04.金融', value: '04.金融' },
          { label: '05.建築製造', value: '05.建築製造' },
          { label: '06.政府單位', value: '06.政府單位' },
          { label: '07.政治', value: '07.政治' },
          { label: '08.美妝', value: '08.美妝' },
          { label: '09.食品', value: '09.食品' },
          { label: '10.娛樂/藝術', value: '10.娛樂/藝術' },
          { label: '11.旅遊', value: '11.旅遊' },
          { label: '12.科技', value: '12.科技' },
          { label: '13.教育', value: '13.教育' },
          { label: '14.菸酒', value: '14.菸酒' },
          { label: '15.零售業', value: '15.零售業' },
          { label: '16.電子商務', value: '16.電子商務' },
          { label: '17.電信', value: '17.電信' },
          { label: '18.醫療保健', value: '18.醫療保健' },
          { label: '19.新聞傳播', value: '19.新聞傳播' },
          { label: '20.科學', value: '20.科學' },
          { label: '21.公益', value: '21.公益' },
          { label: '22.其他', value: '22.其他' },
          { label: '23.公關公司', value: '23.公關公司' },
        ],
        xs: 4,
        position: 'end',
      },
      {
        name: '',
        data: '客戶分級：',
        color: 'red',
        xs: 2,
        position: 'start',
      },
      {
        name: '客戶分級：',
        data: '',
        type: 'select',
        options: [
          { label: 'S', value: 'S' },
          { label: 'A+', value: 'A+' },
          { label: 'A', value: 'A' },
          { label: 'B', value: 'B' },
          { label: 'C', value: 'C' },
        ],
        xs: 4,
      },
      {
        name: '',
        data: '客戶來源：',
        color: 'red',
        xs: 2,
      },
      {
        name: '客戶來源：',
        data: '',
        type: 'select',
        options: [
          { label: '業務開發', value: '業務開發' },
          { label: '公司客', value: '公司客' },
          { label: '公司客（部門轉介）', value: '公司客（部門轉介）' },
          { label: '自來客', value: '自來客' },
        ],
        xs: 4,
        position: 'end',
      },
    ],
  }
  const sells = {
    title: '銷售資料',
    columns: [
      {
        name: '',
        data: '走期(啟)：',
        color: 'red',
        xs: 2,
        position: 'start',
      },
      {
        name: '走期(啟)',
        data: '',
        type: 'date',
        xs: 4,
      },
      {
        name: '',
        data: '走期(迄)：',
        color: 'red',
        xs: 2,
      },
      {
        name: '走期(迄)',
        data: '',
        type: 'date',
        xs: 4,
        position: 'end',
      },
      {
        name: '',
        data: '委刊執行金額：',
        color: 'red',
        xs: 2,
        position: 'start',
        remark: `※銷售金額，以此欄計算客戶KPI。
        ※與客戶簽訂的投放金額。`,
      },
      {
        name: '委刊執行金額',
        data: '',
        xs: 4,
      },
      {
        name: '',
        data: '銷售CPM：',
        color: 'red',
        xs: 2,
      },
      {
        name: '銷售CPM',
        data: '',
        xs: 4,
        position: 'end',
      },
      {
        name: '',
        data: 'AC%：',
        color: 'red',
        xs: 2,
        position: 'start',
      },
      {
        name: 'AC%',
        data: '',
        xs: 4,
      },
      {
        name: '',
        data: 'AC種類：',
        color: 'red',
        xs: 2,
      },
      {
        name: 'AC種類',
        data: '',
        type: 'select',
        options: [
          { label: '內含', value: '內含' },
          { label: '外收', value: '外收' },
        ],
        xs: 4,
        position: 'end',
      },
      {
        name: '',
        data: '委刊KPI(impr.)：',
        xs: 2,
        position: 'start',
      },
      {
        name: '委刊KPI(impr.)：',
        data: '',
        xs: 4,
      },
      {
        name: '',
        data: '銷售毛利：',
        xs: 2,
        remark: '※委刊執行金額-進案金額=銷售毛利',
      },
      {
        name: '銷售毛利：',
        data: '',
        xs: 4,
        position: 'end',
      },
      {
        name: '',
        data: '專案形式：',
        xs: 2,
        position: 'start',
      },
      {
        name: '專案形式：',
        data: '',
        type: 'select',
        options: [
          { label: '一般銷售', value: '一般銷售' },
          {
            label: '贈送，請提供案號(進案單有則不需另外加註)',
            value: '贈送，請提供案號(進案單有則不需另外加註)',
          },
        ],
        xs: 4,
      },
      {
        name: '',
        data: '開戶費：',
        xs: 2,
        remark: '※請填寫數字。※影響ARGO進案項目。',
      },
      {
        name: '開戶費：',
        data: '',
        xs: 4,
        position: 'end',
      },
      {
        name: '',
        data: '優惠方案：',
        xs: 2,
        position: 'start',
      },
      {
        name: '優惠方案：',
        data: '',
        options: [
          { label: '無', value: '無' },
          {
            label: '有，請填入方案代碼(請在信中提供贈送案號)',
            value: '有，請填入方案代碼(請在信中提供贈送案號)',
          },
        ],
        xs: 4,
      },
      {
        name: '',
        data: '開票金額(未稅)',
        xs: 2,
        remark: `驗算欄位，自動帶入。
        ※進案後解方會提供SS聯播網案號，請以此案號開立請款發票。
        ※請確認此欄是否與開票金額相同。
        ※此欄位會對應到ARGO開票金額欄位。
        `,
      },
      {
        name: '開票金額(未稅)',
        data: '',
        xs: 4,
        position: 'end',
      },
      {
        name: '',
        data: '贈送案號',
        xs: 2,
        position: 'start',
        dependency: {
          name: '專案形式：',
          value: '贈送，請提供案號(進案單有則不需另外加註)',
        },
      },
      {
        name: '贈送案號',
        data: '',
        xs: 10,
        position: 'end',
        dependency: {
          name: '專案形式：',
          value: '贈送，請提供案號(進案單有則不需另外加註)',
        },
        type: 'text',
      },
    ],
  }
  const execute = {
    title: '執行資料',
    backgroundColor: '#3d818c',
    columns: [
      {
        name: '',
        data: '進案金額：',
        color: 'red',
        xs: 2,
        position: 'start',
      },
      {
        name: '進案金額：',
        data: '',
        xs: 4,
      },
      {
        name: '',
        data: '進案成本CPM：',
        xs: 2,
        dependency: {
          func: (d) =>
            !['解方建議規劃', '待客戶確認'].includes(d['進案版位：']),
        },
      },
      {
        name: '進案成本CPM：',
        data: '',
        disabled: true,
        xs: 4,
        dependency: {
          func: (d) =>
            !['解方建議規劃', '待客戶確認'].includes(d['進案版位：']),
        },
        position: 'end',
      },
      {
        name: '',
        data: '進案成本CPM：',
        xs: 2,
        dependency: {
          func: (d) => ['解方建議規劃', '待客戶確認'].includes(d['進案版位：']),
        },
        // remark:
        //   '由業務填寫預估的CPM成本預算，以填寫「數字」為主，ex：250，而非文字',
      },
      {
        name: '進案成本CPM：',
        data: '',
        xs: 4,
        disabled: false,
        position: 'end',
        placeholder: '請填寫預算金額，ex：250',
        dependency: {
          func: (d) => ['解方建議規劃', '待客戶確認'].includes(d['進案版位：']),
        },
      },
      {
        name: '',
        data: '進案版位：',
        color: 'red',
        xs: 2,
        position: 'start',
      },
      {
        name: '進案版位：',
        data: '',
        type: 'select',
        options: cpms,
        xs: 4,
      },
      {
        name: '',
        data: '報表形式：',
        xs: 2,
      },
      {
        name: '報表形式：',
        data: '',
        disabled: true,
        xs: 4,
        position: 'end',
      },
      {
        name: '',
        data: '推廣目標：',
        color: 'red',
        xs: 2,
        position: 'start',
      },
      {
        name: '推廣目標：',
        data: '',
        type: 'select',
        options: [
          { label: '曝光', value: '曝光' },
          { label: '互動', value: '互動' },
          { label: '影片觀看數', value: '影片觀看數' },
        ],
        xs: 4,
      },
      {
        name: '',
        data: '曝光KPI(impr.)：',
        xs: 2,
      },
      {
        name: '曝光KPI(impr.)：',
        data: '',
        disabled: true,
        xs: 4,
        position: 'end',
      },
      {
        name: '',
        data: '推廣內容(品牌/產品/活動)：',
        color: 'red',
        xs: 2,
        position: 'start',
      },
      {
        name: '推廣內容(品牌/產品/活動)：',
        data: '',
        xs: 10,
        position: 'end',
        type: 'text',
      },
      {
        name: '',
        data: '推廣內容參考資料：',
        xs: 2,
        position: 'start',
      },
      {
        name: '推廣內容參考資料：',
        data: '',
        xs: 10,
        position: 'end',
        type: 'text',
      },
    ],
  }
  const up = {
    title: '上刊資料：',
    backgroundColor: '#044f5d',
    columns: [
      {
        name: '',
        data: '素材製作：',
        xs: 2,
        position: 'start',
      },
      {
        name: '素材製作：',
        data: '客戶提供',
        type: 'select',
        options: [
          { label: '潮網製作', value: '潮網製作' },
          { label: '客戶提供', value: '客戶提供' },
        ],
        xs: 4,
      },
      {
        name: '',
        data: '素材/完稿連結：',
        xs: 2,
      },
      {
        name: '素材/完稿連結：',
        data: '',
        xs: 4,
        position: 'end',
      },
      {
        name: '',
        data: '',
        xs: 2,
        position: 'start',
        backgroundColor: '#adb1b8',
      },
      {
        name: '',
        data: '提示文字',
        xs: 4,
        backgroundColor: '#adb1b8',
      },
      {
        name: '',
        data: '填寫欄位',
        xs: 6,
        position: 'end',
        backgroundColor: '#adb1b8',
      },
      {
        name: '',
        data: '製作風格：',
        xs: 2,
        position: 'start',
      },
      {
        name: '',
        data: '請提供參考連結或文字描述或附檔於右方欄位或夾於帶信件中。',
        dependency: {
          name: '素材製作：',
          value: '潮網製作',
        },
        xs: 4,
      },
      {
        name: '',
        data: '可跳過此欄位。',
        dependency: {
          name: '素材製作：',
          value: '客戶提供',
        },
        xs: 4,
      },
      {
        name: '製作風格',
        data: '',
        type: 'textarea',
        placeholder: `※請提供於此欄，潮網製作此為必填欄位。
(提供參考圖為佳，但請留意參考圖的方向不要過於衝突。)`,
        xs: 6,
        position: 'end',
      },
      {
        name: '',
        data: '創意腳本：(腳本規劃需求)',
        xs: 2,
        position: 'start',
      },
      {
        name: '',
        data: '若有相關指示資訊請於右欄提供，若無則由解方依產品及推廣目標規劃。(收到需求後2-3days提供)',
        dependency: {
          name: '素材製作：',
          value: '潮網製作',
        },
        xs: 4,
      },
      {
        name: '',
        data: '可跳過此欄位。',
        dependency: {
          name: '素材製作：',
          value: '客戶提供',
        },
        xs: 4,
      },
      {
        name: '創意腳本',
        data: '',
        type: 'textarea',
        placeholder: `※請提供於此欄，若無請留空。
※如品牌針對腳本有預期規劃，請於此欄提出。`,
        xs: 6,
        position: 'end',
      },
      {
        name: '',
        data: '指定內容：',
        xs: 2,
        position: 'start',
      },
      {
        name: '',
        data: '若有相關指示資訊請於右欄提供，若無則由解方依產品及推廣目標規劃。(收到需求後2-3days提供)',
        dependency: {
          name: '素材製作：',
          value: '潮網製作',
        },
        xs: 4,
      },
      {
        name: '',
        data: '可跳過此欄位。',
        dependency: {
          name: '素材製作：',
          value: '客戶提供',
        },
        xs: 4,
      },
      {
        name: '指定內容',
        data: '',
        type: 'textarea',
        placeholder: `※請提供於此欄，若無請留空。
※指定文案／指定素材(若有多素材務必指出哪張必須使用)…等。
※若指定內容僅供延伸不能完整挪用請註明。
※若規劃時未提供此欄資訊，不可以此為由大改素材或增加調整次數。`,
        xs: 6,
        position: 'end',
      },
      {
        name: '',
        data: '導流連結：',
        xs: 2,
        position: 'start',
      },
      {
        name: '',
        type: 'textarea',
        data: `※若有多個連結請自行列點提供。
※若需解方協助設定wttd的UTM請提出，若要自行設定請提供設定好的連結。`,
        xs: 4,
      },
      {
        name: '導流連結',
        data: '',
        placeholder: '※如進案無法提供，請填寫提供時間。',
        xs: 6,
        position: 'end',
      },
      {
        name: '',
        data: '裝置設定：',
        xs: 2,
        position: 'start',
      },
      {
        name: '裝置設定：',
        data: '',
        type: 'select',
        options: [
          { label: 'Mobile', value: 'Mobile' },
          {
            label: '移動裝置(Mobile/Tablet)',
            value: '移動裝置(Mobile/Tablet)',
          },
          {
            label: 'Mobile&PC(※置底及蓋版版位不可選)',
            value: 'Mobile&PC(※置底及蓋版版位不可選)',
          },
          {
            label: 'Mobile&Tablet&PC(※置底及蓋版版位不可選)',
            value: 'Mobile&Tablet&PC(※置底及蓋版版位不可選)',
          },
        ],
        xs: 10,
        position: 'end',
      },
      {
        name: '',
        data: '目標受眾：',
        xs: 2,
        position: 'start',
      },
      {
        name: '目標受眾：',
        data: '',
        placeholder: `※推廣產品TA性別/年齡等。
※其他說明補充/要求。`,
        type: 'textarea',
        xs: 10,
        position: 'end',
      },
    ],
  }

  const [datas, setdatas] = useState({
    走期天數: '',
  })
  // const [columns, setcolumns] = useState(
  //   [basic, sells, execute, up].map((c) => ({
  //     title: c.title,
  //     columns: c.columns,
  //   }))
  // )
  useEffect(() => {
    const temp = {}
    basic.columns
      .concat(sells.columns)
      .concat(execute.columns)
      .concat(up.columns)
      .forEach(({ name, data }) => {
        if (name) {
          temp[name] = data
        }
      })
    setdatas({
      ...datas,
      ...temp,
    })
  }, [])
  const handleDataChange = (e) => {
    setdatas({
      ...datas,
      [e.target.name]: e.target.value,
    })
  }

  const formula = {
    走期天數: () => {
      if (datas['走期(啟)'] && datas['走期(迄)']) {
        return (
          moment(datas['走期(迄)']).diff(moment(datas['走期(啟)']), 'days') + 1
        )
      }
      return 0
    },
    '委刊KPI(impr.)：': () =>
      datas['銷售CPM']
        ? parseInt(
            (parseInt(datas['委刊執行金額'] || 0, 10) /
              parseFloat(datas['銷售CPM'])) *
              1000,
            10
          )
        : 0,
    '開票金額(未稅)': () =>
      (parseInt(datas['委刊執行金額'] || 0, 10) *
        (datas['AC種類'] === '外收' ? 100 + (datas['AC%'] || 0) : 100)) /
        100 +
      parseInt(datas['銷售毛利：'] || 0, 10) +
      parseInt(datas['開戶費：'] || 0, 10),
    '進案成本CPM：': () =>
      ['解方建議規劃', '待客戶確認'].includes(datas['進案版位：'])
        ? datas['進案成本CPM：']
        : cpms.find((cpm) => cpm.value === datas['進案版位：'])?.cpm,
    '報表形式：': () =>
      datas['專案形式：'] === '贈送，請提供案號(進案單有則不需另外加註)'
        ? '結案報告'
        : datas['結案天數'] < 10
        ? '結案報告'
        : datas['進案金額：'] / datas['結案天數'] < 800
        ? '雙週報'
        : '週報',
    '曝光KPI(impr.)：': () =>
      datas['進案版位：']
        ? parseInt(
            (parseFloat(datas['進案金額：'] || 0) /
              parseInt(
                cpms.find((cpm) => cpm.value === datas['進案版位：'])?.cpm ||
                  datas['進案成本CPM：'],
                10
              )) *
              1000,
            10
          )
        : 0,
  }

  const check = {
    '委刊KPI(impr.)：': {
      func: (value) => value && value >= 135000,
      message: '委刊KPI需大於135000',
    },
    委刊執行金額: {
      func: (value, refer) => value && value >= refer['進案金額：'],
      message: '委刊執行金額需大於等於進案金額',
    },
    必填欄位: {
      func: (value, refer) => {
        const list = [basic, sells, execute, up]
        return list.every(({ columns }) =>
          columns
            .filter(({ color }) => color === 'red')
            .every(({ data }) => refer[data] !== '')
        )
      },
      message: '紅字欄位為必填欄位',
    },
    '曝光KPI(impr.)：': {
      func: (value, refer) => value && value === refer['委刊KPI(impr.)：'],
      message: '曝光KPI需等於委刊KPI',
    },
  }
  const checked = useMemo(() => {
    const uploadForm = {}
    Object.keys(datas).forEach((key) => {
      uploadForm[key] = formula[key] ? formula[key]() || '' : datas[key]
    })
    const errors = []
    Object.keys(check).forEach((key) => {
      if (!check[key].func(uploadForm[key], uploadForm)) {
        errors.push({ key, message: check[key].message })
      }
    })
    return errors
  }, [datas])

  const [files, setfiles] = useState([])

  const getHtml = () => {
    const conbined = [basic, sells, execute, up]
    const extraContent = getFixedForm(auth)

    const html = `<div style="width:750px">
      <div style="width:100%;background-color:#0c5394;color:#fff;border:inset 1px;">
        申請人資訊
      </div>
      ${extraContent
        .map(
          ({ name, value }, i) =>
            `${
              i % 2 === 0
                ? '<div class="flex-wrap" style="width:100%;display:flex;border:inset 1px">'
                : ''
            }
                <div style="width:19%;border:inset 1px">
                  ${name}
                </div>
                <div style="width:29%;border:inset 1px">
                  ${value}
                </div>
            ${i % 2 === 1 ? '</div>' : ''}`
        )
        .join('')}
        ${
          checked.length
            ? `<div style="width:100%;background-color:#0c5394;color:#fff;border:inset 1px;">
        驗證未通過資訊
      </div>`
            : ''
        }
        ${
          checked.length
            ? checked
                .map(
                  ({ key, message }) =>
                    `<div class="flex-wrap" style="width:100%;display:flex;border:inset 1px">
                      <div style="width:25%;border:inset 1px">
                        ${key}
                      </div>
                      <div style="width:75%;border:inset 1px">
                        ${message}
                      </div>
                    </div>`
                )
                .join('')
            : ''
        }
        <div style="display:flex">
          <div  style="width:25%;margin-left:auto;border:inset 1px">
            <p>走期天數</p>
          </div>
          <div  style="width:25%;border:inset 1px">
            <p>${formula['走期天數']()}</p>
          </div>
        </div>
    ${conbined
      .map(
        ({ title, backgroundColor, columns }) =>
          `<div style="width:100%;background-color:${
            backgroundColor || '#0c5394'
          };color:#fff;border:inset 1px;">
        ${title}
      </div>
      
        ${columns
          .map(
            ({
              name,
              xs,
              color,
              data,
              // placeholder,
              dependency,
              // disabled,
              // type = 'text',
              // options = [],
              position,
            }) =>
              !dependency ||
              (dependency.func
                ? dependency.func(datas)
                : datas[dependency.name] === dependency.value)
                ? `${
                    position === 'start'
                      ? '<div class="flex-wrap" style="width:100%;display:flex;border:inset 1px">'
                      : ''
                  }<div style="width:${parseInt(
                    (xs / 12) * 100,
                    10
                  )}%;color:${color};border:inset 1px">
            ${
              name
                ? `<input style="width:100%;height:100%;border:none" type="text" disabled value="${
                    formula[name] ? formula[name]() || '' : datas[name]
                  }" />`
                : data
            }
          </div>${position === 'end' ? '</div>' : ''}`
                : ''
          )
          .join('')}
      `
      )
      .join('')}</div>`
    return html
  }

  const handleSubmit = async () => {
    const html = getHtml()
    const getArrayBuffer = (file) =>
      new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
          resolve(reader.result)
        })
        reader.readAsArrayBuffer(file)
      })
    const temp = []
    for (let i = 0; i < files.length; i += 1) {
      temp.push(getArrayBuffer(files[i].blob))
    }
    const buffered = await Promise.all(temp)
    const arrayed = buffered.map((buffer, i) => ({
      name: files[i].name,
      data: Array.from(new Uint8Array(buffer)),
    }))
    const uploaded = await apiService.data({
      path: `file`,
      method: 'post',
      data: {
        files: JSON.stringify(arrayed),
      },
    })
    const extraContent = getFixedForm(auth)
    await apiService.data({
      path: 'luca/form/mail',
      method: 'post',
      data: {
        html,
        subject: `【創意版位採購需求】(${
          extraContent[5].value
        })@${moment().format('yyMMDD')}`,
        files: uploaded,
      },
    })
    handleClose(true)
  }

  numeral.zeroFormat('0')
  numeral.nullFormat('')

  const modalRef = useRef(null)
  useEffect(() => {
    if (modalRef.current) modalRef.current.dialog.scrollTop = 0
  }, [doublechecking])

  return (
    <Modal
      show={show}
      size="xl"
      onHide={handleClose}
      style={{
        zIndex: '9999',
      }}
      ref={modalRef}
    >
      {doublechecking ? (
        <>
          <Modal.Header
            className="AccFormModal justify-content-center text-center pt-4"
            closeButton
          >
            <Modal.Title>
              <h4>表 單 送 出 確 認</h4>
              <p>Form Sending Confirmation</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-wrap borderTable">
            <h4 className="text-danger text-center py-3">
              【注意】您正在為 {auth.account.name} 提出進案需求。
              <br />
            </h4>
            <Row className="mt-1 px-2">
              <Col className="p-1 d-flex text-center ms-auto" xs={3}>
                <p className="my-auto">走期天數</p>
              </Col>
              <Col className="p-1 d-flex text-center" xs={3}>
                <Form.Control
                  name="走期天數"
                  className="border-0 h-100 w-100 m-auto"
                  value={formula['走期天數']()}
                  type="tel"
                  // onChange={handleDataChange}
                  // placeholder="輸入走期"
                  disabled
                />
              </Col>
            </Row>
            {[basic, sells, execute, up].map(({ title, columns, ...t }) => (
              <React.Fragment key={title}>
                <Row className="mt-1 px-2">
                  <Col
                    style={{
                      backgroundColor: t.backgroundColor || '#0c5394',
                      color: '#fff',
                    }}
                    xs={12}
                  >
                    {title}
                  </Col>
                </Row>
                <Row className="px-2">
                  {columns.map(
                    (
                      {
                        name,
                        xs,
                        color,
                        backgroundColor,
                        data,
                        placeholder,
                        dependency,
                        disabled,
                        remark,
                        type = 'text',
                        // options = [],
                      },
                      i
                    ) =>
                      !dependency ||
                      (dependency.func
                        ? dependency.func(datas)
                        : datas[dependency.name] === dependency.value) ? (
                        <React.Fragment key={i}>
                          <Col
                            className="p-1 d-flex text-center narwhal-datepicker"
                            style={{
                              color,
                              backgroundColor: checked.find(
                                ({ key }) => key === name
                              )
                                ? 'red'
                                : backgroundColor,
                            }}
                            title={
                              checked.find(({ key }) => key === name)
                                ? checked.message
                                : ''
                            }
                            xs={xs}
                          >
                            {name ? (
                              type === 'text' ? (
                                <Form.Control
                                  name={name}
                                  className="border-0 h-100 w-100 m-auto"
                                  value={formula[name] || datas[name]}
                                  type={type}
                                  onChange={handleDataChange}
                                  disabled={
                                    disabled === false
                                      ? false
                                      : formula[name] !== undefined
                                  }
                                  placeholder={placeholder || ''}
                                  readOnly
                                />
                              ) : type === 'number' ? (
                                <NumericFormat
                                  thousandSeparator
                                  value={
                                    formula[name] ? formula[name]() : data[name]
                                  }
                                  onValueChange={(e) =>
                                    handleDataChange({
                                      target: {
                                        name,
                                        value: e.floatValue,
                                      },
                                    })
                                  }
                                />
                              ) : type === 'textarea' ? (
                                <Form.Control
                                  name={name}
                                  className="border-0 h-100 w-100 m-auto"
                                  value={
                                    formula[name]
                                      ? formula[name]() || ''
                                      : datas[name]
                                  }
                                  type={type}
                                  as="textarea"
                                  rows={3}
                                  onChange={handleDataChange}
                                  disabled={
                                    disabled === false
                                      ? false
                                      : formula[name] !== undefined
                                  }
                                  placeholder={placeholder || ''}
                                  readOnly
                                />
                              ) : type === 'date' ? (
                                <ReactDatePicker
                                  disabled
                                  selected={
                                    datas[name]
                                      ? moment(datas[name]).toDate()
                                      : datas[name]
                                  }
                                  dateFormat="yyyy/MM/dd"
                                  onChange={(date) => {
                                    handleDataChange({
                                      target: {
                                        name,
                                        value:
                                          moment(date).format('yyyy/MM/DD'),
                                      },
                                    })
                                  }}
                                />
                              ) : (
                                <p className="my-auto text-start">
                                  {datas[name]}
                                </p>
                              )
                            ) : (
                              <p className="my-auto text-start">
                                {data || placeholder}
                              </p>
                            )}
                            {remark && <Question setting={{ remark }} />}
                          </Col>
                        </React.Fragment>
                      ) : (
                        <div className="d-none" />
                      )
                  )}
                </Row>
              </React.Fragment>
            ))}
            <Row className="mt-1 px-2">
              <Col
                className="d-flex pe-0"
                style={{
                  backgroundColor: '#0c5394',
                  color: '#fff',
                }}
                xs={12}
              >
                <span className="my-auto">附加檔案</span>
              </Col>
            </Row>
            <Row
              className="px-2 overflow-scroll"
              style={{
                height: '28vh',
              }}
            >
              {files.length ? (
                files.map(({ name, url }, i) => (
                  <Col className="h-100" key={url} xs={4}>
                    <Row className="text-center">
                      <Col>{name}</Col>
                      <Col className="d-flex">
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          className="mx-2 ms-auto my-auto"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            setfiles(files.filter((f, j) => i !== j))
                          }
                        />
                      </Col>
                    </Row>
                    <Row
                      className="p-2"
                      style={{
                        height: '90%',
                      }}
                    >
                      <Image
                        style={{
                          height: '100%',
                          width: 'auto',
                        }}
                        src={url}
                      />
                    </Row>
                  </Col>
                ))
              ) : (
                <Col className="d-flex">
                  <span className="m-auto">無附加檔案</span>
                </Col>
              )}
            </Row>
          </Modal.Body>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Row className="w-100">
              <Col xs={8}>
                <h4>Narwhal 工單</h4>
              </Col>
              <Col xs={4}>
                <Form.Check
                  label="允許未通過標準檢查的數值"
                  checked={!strict}
                  onChange={() => setstrict(!strict)}
                />
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body className="text-wrap borderTable">
            <Row className="mt-1 px-2">
              <Col className="p-1 d-flex text-center ms-auto" xs={3}>
                <p className="my-auto">走期天數</p>
              </Col>
              <Col className="p-1 d-flex text-center" xs={3}>
                <Form.Control
                  name="走期天數"
                  className="border-0 h-100 w-100 m-auto"
                  value={formula['走期天數']()}
                  type="tel"
                  // onChange={handleDataChange}
                  // placeholder="輸入走期"
                  disabled
                />
              </Col>
            </Row>
            {[basic, sells, execute, up].map(({ title, columns, ...t }) => (
              <React.Fragment key={title}>
                <Row className="mt-1 px-2">
                  <Col
                    style={{
                      backgroundColor: t.backgroundColor || '#0c5394',
                      color: '#fff',
                    }}
                    xs={12}
                  >
                    {title}
                  </Col>
                </Row>
                <Row className="px-2">
                  {columns.map(
                    (
                      {
                        name,
                        xs,
                        color,
                        backgroundColor,
                        data,
                        placeholder,
                        dependency,
                        disabled,
                        remark,
                        type = 'number',
                        options = [],
                      },
                      i
                    ) =>
                      !dependency ||
                      (dependency.func
                        ? dependency.func(datas)
                        : datas[dependency.name] === dependency.value) ? (
                        <React.Fragment key={i}>
                          <Col
                            className="p-1 d-flex text-center narwhal-datepicker"
                            style={{
                              color,
                              backgroundColor: checked.find(
                                ({ key }) => key === name
                              )
                                ? 'red'
                                : backgroundColor,
                            }}
                            title={
                              checked.find(({ key }) => key === name)
                                ? checked.message
                                : ''
                            }
                            xs={xs}
                          >
                            {name ? (
                              type === 'text' ? (
                                <Form.Control
                                  name={name}
                                  className="border-0 h-100 w-100 m-auto"
                                  value={formula[name] || datas[name]}
                                  type={type}
                                  onChange={handleDataChange}
                                  disabled={
                                    disabled === false
                                      ? false
                                      : formula[name] !== undefined
                                  }
                                  placeholder={placeholder || ''}
                                />
                              ) : type === 'number' ? (
                                <NumericFormat
                                  className="border-0 w-100 rounded"
                                  thousandSeparator
                                  value={
                                    formula[name] ? formula[name]() : data[name]
                                  }
                                  onValueChange={(e) =>
                                    handleDataChange({
                                      target: {
                                        name,
                                        value: e.floatValue,
                                      },
                                    })
                                  }
                                />
                              ) : type === 'textarea' ? (
                                <Form.Control
                                  name={name}
                                  className="border-0 h-100 w-100 m-auto"
                                  value={
                                    formula[name]
                                      ? formula[name]() || ''
                                      : datas[name]
                                  }
                                  type={type}
                                  as="textarea"
                                  rows={3}
                                  onChange={handleDataChange}
                                  disabled={
                                    disabled === false
                                      ? false
                                      : formula[name] !== undefined
                                  }
                                  placeholder={placeholder || ''}
                                />
                              ) : type === 'date' ? (
                                <ReactDatePicker
                                  disabled={disabled}
                                  selected={
                                    datas[name]
                                      ? moment(datas[name]).toDate()
                                      : datas[name]
                                  }
                                  dateFormat="yyyy/MM/dd"
                                  onChange={(date) => {
                                    handleDataChange({
                                      target: {
                                        name,
                                        value:
                                          moment(date).format('yyyy/MM/DD'),
                                      },
                                    })
                                  }}
                                />
                              ) : (
                                <Dropdown className="my-auto w-100 h-100 custom-dropdown">
                                  <Dropdown.Toggle
                                    className="btn-outline px-1 fs-8 h-100 border-0"
                                    style={{
                                      backgroundColor:
                                        cpms.find(
                                          (cpm) => cpm.value === datas[name]
                                        )?.backgroundColor || 'transparent',
                                    }}
                                    id="dropdown-basic"
                                    size="sm"
                                  >
                                    {datas[name]}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu
                                    className="px-3 overflow-scroll"
                                    style={{
                                      maxHeight: '25vh',
                                    }}
                                  >
                                    {options.map((option, k) => (
                                      <Dropdown.Item
                                        className="rounded mb-2"
                                        key={k}
                                        onClick={() => {
                                          handleDataChange({
                                            target: {
                                              name,
                                              value: option.value,
                                            },
                                          })
                                        }}
                                        style={{
                                          backgroundColor:
                                            option.backgroundColor,
                                        }}
                                      >
                                        {option.label}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )
                            ) : (
                              <p className="my-auto text-start">
                                {data || placeholder}
                              </p>
                            )}
                            {remark && <Question setting={{ remark }} />}
                          </Col>
                        </React.Fragment>
                      ) : (
                        <div className="d-none" />
                      )
                  )}
                </Row>
              </React.Fragment>
            ))}
            <Row className="mt-1 px-2">
              <Col
                className="d-flex pe-0"
                style={{
                  backgroundColor: '#0c5394',
                  color: '#fff',
                }}
                xs={8}
              >
                <span className="my-auto">附加檔案</span>
              </Col>
              <Col
                className="d-flex ps-0"
                style={{
                  backgroundColor: '#0c5394',
                  color: '#fff',
                }}
                xs={4}
              >
                <Button
                  className="d-flex text-nowrap me-1 ms-auto my-auto border-0"
                  style={{
                    backgroundColor: '#0c5394',
                    color: '#fff',
                  }}
                >
                  <Form.Label className="m-auto" htmlFor="file">
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      className="mx-2 ms-0 my-auto"
                    />
                    <Form.Control
                      id="file"
                      name="file"
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        setfiles([
                          {
                            name: e.target.files[0].name,
                            url: URL.createObjectURL(e.target.files[0]),
                            blob: e.target.files[0],
                          },
                          ...files,
                        ])
                        e.target.value = ''
                        e.stopPropagation()
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                      className="p-0 m-0 border-0"
                      style={{
                        visibility: 'hidden',
                        width: '0px',
                        height: '0px',
                      }}
                    />
                  </Form.Label>
                </Button>
              </Col>
            </Row>
            <Row
              className="px-2 overflow-scroll"
              style={{
                height: '28vh',
              }}
            >
              {files.length ? (
                files.map(({ name, url }, i) => (
                  <Col className="h-100" key={url} xs={4}>
                    <Row className="text-center">
                      <Col>{name}</Col>
                      <Col className="d-flex">
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          className="mx-2 ms-auto my-auto"
                          style={{
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            setfiles(files.filter((f, j) => i !== j))
                          }
                        />
                      </Col>
                    </Row>
                    <Row
                      className="p-2"
                      style={{
                        height: '90%',
                      }}
                    >
                      <Image
                        style={{
                          height: '100%',
                          width: 'auto',
                        }}
                        src={url}
                      />
                    </Row>
                  </Col>
                ))
              ) : (
                <Col className="d-flex">
                  <span className="m-auto">無附加檔案</span>
                </Col>
              )}
            </Row>
            {checked.length ? (
              <>
                <Row className="mt-1 px-2">
                  <Col
                    style={{
                      backgroundColor: '#0c5394',
                      color: '#fff',
                    }}
                    xs={12}
                  >
                    錯誤說明
                  </Col>
                </Row>
                {checked.map((c) => (
                  <Row className="mt-1 px-2" key={c.key}>
                    <Col
                      className="p-1 d-flex text-center ms-auto text-red"
                      xs={3}
                    >
                      <p className="my-auto">{c.key}</p>
                    </Col>
                    <Col className="p-1 d-flex text-center text-red" xs={9}>
                      <p className="my-auto text-danger">{c.message}</p>
                    </Col>
                  </Row>
                ))}
              </>
            ) : (
              <div />
            )}
            {/* <Row>
          <Col xs={12}>進案資料(※Ragic及ARGO登記資料，請務必填妥)</Col>
        </Row>
        <Row>
          <Col xs={2}>Ragic委刊單號：</Col>
          <Col xs={4}>MB202402300</Col>
          <Col xs={2}>Ragic委刊單號：</Col>
          <Col xs={4}>MB202402300</Col>
        </Row>
        <Row>
          <Col xs={2}>Ragic委刊單號：</Col>
          <Col xs={4}>MB202402300</Col>
          <Col xs={2}>Ragic委刊單號：</Col>
          <Col xs={4}>MB202402300</Col>
        </Row> */}
          </Modal.Body>
        </>
      )}
      <Modal.Footer>
        <Button
          onClick={() => {
            if (doublechecking) setdoublechecking(false)
            else handleClose()
          }}
          variant="secondary"
        >
          {doublechecking ? '返回修改' : '取 消'}
        </Button>
        <Button
          onClick={() => {
            if (doublechecking) handleSubmit()
            else setdoublechecking(true)
          }}
          disabled={strict && checked.length}
          variant="luca"
        >
          {doublechecking ? '送 出' : '完 成'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

Narwhal.propTypes = {
  setting: PropTypes.shape().isRequired,
}

function OpenAccount(props) {
  const { action } = props
  const { platform } = useParams()
  const auth = useContext(AuthContext)
  const { settoast } = useContext(ToastContext)

  const navigate = useNavigate()

  const [showInfo, setshowInfo] = useState(false)
  const [form, setform] = useState({
    pageLimit: 2,
    content: [
      { name: '用戶名稱', type: 'fixed', value: 'LucaManager' },
      { name: '帳戶名稱', type: 'text', value: '' },
      { name: '公司統編', type: 'text', value: '' },
      { name: '電子信箱', type: 'email', value: '' },
    ],
  })
  const [formData, setformData] = useState({ content: {} })
  const handleShowInfo = () => setshowInfo(true)
  const handleCloseInfo = () => {
    setshowInfo(false)
  }
  const [showForm, setshowForm] = useState(false)
  const handleShowForm = (value) => {
    const content = {}
    value.content.forEach((c) => {
      content[c.name] = c.type === 'fixed' ? c.value : ''
    })
    const extraContent = getFixedForm(auth)
    extraContent.forEach((c) => {
      content[c.name] = c.value
    })
    setformData({ ...value, content })
    setform({
      ...value,
      fixedContent: extraContent
        .concat([...value.content])
        .filter((c) => c.type === 'fixed'),
      content: extraContent
        .concat([...value.content])
        .filter((c) => c.type !== 'fixed'),
    })
    setshowForm(true)
  }
  const handleDataChange = (event) => {
    setformData({
      ...formData,
      content: {
        ...formData.content,
        [event.target.name]: event.target.files || event.target.value,
      },
    })
  }
  // warn control
  const [showWarn, setshowWarn] = useState({
    show: false,
    shouldReturn: true,
  })
  const handleSendForm = async (value) => {
    const data = {}
    Object.keys(value.content)
      .filter((key) => {
        const content = form.content.filter((f) => f.name === key)
        if (content.length > 0) {
          return content.find(
            (c) =>
              !c.dependency ||
              value.content[c.dependency.name] === c.dependency.value
          )
        }
        return true
      })
      .forEach((key) => {
        data[key] = value.content[key]
      })
    const res = await apiService.form('post', {
      ...value,
      setting: { ...value.setting, status: '待 處 理' },
      content: data,
      user: auth.auth.user_id,
      business: auth.account.business_id,
    })
    if (!res.error) {
      setshowWarn({
        ...showWarn,
        title: '已 送 出',
        titleEng: 'Form Sending Confirmation',
        text: <div />,
        warn: (
          <div className="h-100 w-100 d-flex flex-column">
            <FontAwesomeIcon
              className="h0 mx-auto mt-auto mb-3 text-luca align-self-center"
              icon={faCheckCircle}
            />
            <h3 className="mx-auto mb-auto text-luca">已 送 出 !</h3>
            <h3 className="mx-auto mb-auto text-luca">現 在 儲 值 ？</h3>
          </div>
        ),
        footer: (
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setshowWarn({
                  ...showWarn,
                  show: false,
                })
                handleShowInfo(true)
              }}
            >
              確 定
            </Button>
            <Button
              variant="luca"
              onClick={() => {
                navigate('/BillRecords?action=recharge')
              }}
            >
              前 往 儲 值
            </Button>
          </>
        ),
        handleClose: () => {
          setshowWarn((prevState) => ({ ...prevState, show: false }))
          handleShowInfo(true)
        },
        show: true,
      })
    }
  }
  const handleWarnClose = async (value) => {
    if (value) {
      const getArrayBuffer = (file) =>
        new Promise((resolve) => {
          const reader = new FileReader()
          reader.addEventListener('load', () => {
            resolve({ name: file.name, result: reader.result })
          })
          reader.readAsArrayBuffer(file)
        })
      const files = []
      Object.keys(formData.content).forEach((key) => {
        if (typeof formData.content[key] === 'object') {
          files.push(getArrayBuffer(formData.content[key][0]))
        }
      })
      const buffered = await Promise.all(files)
      const arrayed = buffered.map((buffer) => ({
        name: buffer.name,
        data: Array.from(new Uint8Array(buffer.result)),
      }))
      const uploaded = await apiService.data({
        path: `file`,
        method: 'post',
        data: {
          files: JSON.stringify(arrayed),
        },
      })
      if (uploaded.error) {
        if (
          uploaded.error.response &&
          uploaded.error.response.status === '413'
        ) {
          settoast({ show: true, text: '上 傳 失 敗 ， 檔 案 過 大' })
        } else {
          settoast({ show: true, text: '上 傳 失 敗 ， 請 重 試' })
        }
        return
      }
      handleSendForm({ ...formData, files: uploaded })
      setshowWarn({
        ...showWarn,
        show: false,
      })
    } else setshowForm(true)
  }
  const handleShowWarn = (value) => {
    setshowWarn({
      ...value,
      show: true,
      shouldReturn: true,
    })
  }

  const [managed, setmanaged] = useState(false)
  useEffect(() => {
    if (!auth.auth.user_id) return
    const getManaged = async () => {
      const res = await apiService.data({
        path: `luca/accountUser/${auth.auth.user_id}/${auth.account.business_id}`,
        method: 'get',
      })
      setmanaged(res)
    }
    getManaged()
  }, [auth])

  // check user's has accounts access
  const hasAccounts = (provider) => {
    const exist = managed.find((m) => m.provider === provider)
    if (!exist)
      handleShowWarn({
        title: '前 往 開 戶',
        titleEng: 'Create Account',
        img: faSmileWink,
        text: (
          <h5 className="mb-3 text-lucaDark lh-lg">
            還 沒 有 {provider} 帳 戶 嗎 ?
            <br /> 立 即 點 擊 下 方 按 鈕 開 戶 吧 !
          </h5>
        ),
        footer: (
          <Link
            className="w-100 d-flex justify-content-center"
            to={`/applyAccount/${provider}`}
            onClick={() => {
              setshowWarn({
                show: false,
              })
            }}
          >
            <Button variant="lucaLight">確 定</Button>
          </Link>
        ),
        handleClose: () => {
          setshowWarn({
            ...showWarn,
            show: false,
          })
        },
      })
    return !exist
  }

  const getFormSetting = (formType, formPlatform) =>
    getForm(formType, formPlatform, managed)

  // forms
  const permissionForms = [
    {
      title: 'Meta',
      // content:
      //   '全球超過27億月活躍用戶，85％台灣人愛用社群平台，即刻開戶加入Metaverse。',
      footer: '約 1-3 工作日',
      imgSrc: accMeta,
      method: () => {
        if (hasAccounts('facebook')) return
        handleShowForm(getFormSetting('Permission', 'Facebook'))
      },
    },
    {
      title: 'Google',
      // content: '透過主流搜尋引擎和GDN聯播網精準鎖定客群。',
      footer: '約 1-3 工作日',
      imgSrc: accGoogle,
      method: () => {
        if (hasAccounts('google')) return
        handleShowForm(getFormSetting('Permission', 'Google'))
      },
    },
    {
      title: 'LinkedIn',
      // content: '打入LinkedIn生活圈，發掘潛在客戶。',
      footer: '約 1-3 工作日',
      imgSrc: accLinkedin,
      method: () => {
        // if (hasAccounts('LinkedIn')) return
        handleShowForm(getFormSetting('Permission', 'LinkedIn'))
      },
    },
    {
      title: 'Taboola原生廣告',
      // content:
      //   '擁有龐大的內容分析資料庫，以精準且獨家的解決方案幫您鎖定對的人。',
      footer: '約 7-10 工作日',
      imgSrc: accTaboola,
      method: () => {
        // if (hasAccounts('Taboola')) return
        handleShowForm(getFormSetting('Permission', 'Taboola'))
      },
    },
    {
      title: 'Yahoo關鍵字',
      // content: '在Yahoo服務秀出廣告，發現無限商機。',
      footer: '約 7-14 工作日',
      imgSrc: accYahoo,
      method: () => {
        // if (hasAccounts('YahooMA')) return
        handleShowForm(getFormSetting('Permission', 'YahooMA'))
      },
    },
    {
      title: 'Line',
      // content: '打入Line生活圈，發掘潛在客戶。',
      footer: '約 3-5 工作日',
      imgSrc: accLine,
      method: () => {
        // if (hasAccounts('Line')) return
        handleShowForm(getFormSetting('Permission', 'Line'))
      },
    },
    {
      title: 'Criteo',
      // content: '打入Criteo生活圈，發掘潛在客戶。',
      footer: '約 1-3 工作日',
      imgSrc: accCriteo,
      method: () => {
        // if (hasAccounts('Criteo')) return
        handleShowForm(getFormSetting('Permission', 'Criteo'))
      },
    },
    {
      title: 'Dcard',
      // content:
      //   '每月擁有2000萬不重複訪客，趨勢話題快速傳播，立即加入台灣最大社群討論平台',
      footer: '約 7-10 工作日',
      imgSrc: accTypeDcard,
      method: () => {
        // if (hasAccounts('Criteo')) return
        handleShowForm(getFormSetting('Permission', 'Dcard'))
      },
    },
    {
      title: 'Tik Tok',
      // content: '10億全球月活躍用戶，運用Tiktok打響品牌行銷力。',
      footer: '約 5-7 工作日',
      imgSrc: accTypeTiktok,
      method: () => {
        // if (hasAccounts('Criteo')) return
        handleShowForm(getFormSetting('Permission', 'Tiktok'))
      },
    },
  ]

  const billingForms = [
    {
      title: 'Meta',
      // content:
      //   '全球超過27億月活躍用戶，85％台灣人愛用社群平台，即刻開戶加入Metaverse。',
      footer: '約 1-3 工作日',
      imgSrc: accMeta,
      method: () => {
        if (hasAccounts('facebook')) return
        handleShowForm(getFormSetting('Recharge', 'Facebook'))
      },
    },
    {
      title: 'Google',
      // content: '透過主流搜尋引擎和GDN聯播網精準鎖定客群。',
      footer: '約 1-3 工作日',
      imgSrc: accGoogle,
      method: () => {
        if (hasAccounts('google')) return
        handleShowForm(getFormSetting('Recharge', 'Google'))
      },
    },
    {
      title: 'X',
      // content: '打入Twitter生活圈，發掘潛在客戶。',
      footer: '約 3-5 工作日',
      imgSrc: accTwitter,
      method: () => {
        // if (hasAccounts('Twitter')) return
        handleShowForm(getFormSetting('Recharge', 'Twitter'))
      },
    },
    {
      title: 'Taboola原生廣告',
      // content:
      //   '擁有龐大的內容分析資料庫，以精準且獨家的解決方案幫您鎖定對的人。',
      footer: '約 7-10 工作日',
      imgSrc: accTaboola,
      method: () => {
        // if (hasAccounts('YahooMA')) return
        handleShowForm(getFormSetting('Recharge', 'Taboola'))
      },
    },
    {
      title: 'Yahoo關鍵字',
      // content: '在Yahoo服務秀出廣告，發現無限商機。',
      footer: '約 5-7 工作日',
      imgSrc: accYahoo,
      method: () => {
        // if (hasAccounts('YahooMA')) return
        handleShowForm(getFormSetting('Recharge', 'YahooMA'))
      },
    },
    {
      title: 'Tik Tok',
      // content: '10億全球月活躍用戶，運用Tiktok打響品牌行銷力。',
      footer: '約 5-7 工作日',
      imgSrc: accTypeTiktok,
      method: () => {
        // if (hasAccounts('Criteo')) return
        handleShowForm(getFormSetting('Recharge', 'Tiktok'))
      },
    },
  ]

  const [showNarwhal, setshowNarwhal] = useState(false)
  const handleShowNarwhal = () => setshowNarwhal(true)
  const handleNarwhalClose = (value) => {
    if (value) {
      setshowWarn({
        ...showWarn,
        title: '已 送 出',
        titleEng: 'Form Sending Confirmation',
        text: <div />,
        warn: (
          <div className="h-100 w-100 d-flex flex-column">
            <FontAwesomeIcon
              className="h0 mx-auto mt-auto mb-3 text-luca align-self-center"
              icon={faCheckCircle}
            />
            <h3 className="mx-auto mb-auto text-luca">已 送 出 !</h3>
          </div>
        ),
        footer: (
          <Button
            variant="secondary"
            onClick={() => {
              setshowWarn({
                ...showWarn,
                show: false,
              })
              // handleShowInfo(true)
            }}
          >
            確 定
          </Button>
        ),
        handleClose: () => {
          setshowWarn((prevState) => ({ ...prevState, show: false }))
          // handleShowInfo(true)
        },
        show: true,
      })
    }
    setshowNarwhal(false)
  }

  const accountForms = [
    {
      title: 'Meta',
      // content:
      //   '全球超過27億月活躍用戶，85％台灣人愛用社群平台，即刻開戶加入Metaverse。',
      footer: '約 1-3 工作日',
      imgSrc: accMeta,
      platform: 'Facebook',
      method: () => handleShowForm(getFormSetting('OpenAccount', 'Facebook')),
    },
    {
      title: 'Google',
      // content:
      //   '擁有多元廣告形式與龐大數據庫，涵蓋超過300萬個網站，馬上接觸95%的網路用戶。',
      footer: '約 1-3 工作日',
      imgSrc: accGoogle,
      platform: 'Google',
      method: () => handleShowForm(getFormSetting('OpenAccount', 'Google')),
    },
    {
      title: 'X',
      // content:
      //   '全球廣告可觸及超過3.5億用戶，無須登入、趨勢話題快速傳播、即時性最高的資訊平台。',
      footer: '約 7-14 工作日',
      imgSrc: accTwitter,
      platform: 'Twitter',
      method: () => handleShowForm(getFormSetting('OpenAccount', 'Twitter')),
    },
    {
      title: 'LinkedIn',
      // content: '全球最大的商業人士社群網路，用專業內容展開互動。',
      footer: '約 7-14 工作日',
      imgSrc: accLinkedin,
      platform: 'LinkedIn',
      method: () => handleShowForm(getFormSetting('OpenAccount', 'LinkedIn')),
    },
    {
      title: 'Taboola原生廣告',
      // content:
      //   '擁有龐大的內容分析資料庫，以精準且獨家的解決方案幫您鎖定對的人。',
      footer: '約 7-10 工作日',
      imgSrc: accTaboola,
      platform: 'Taboola',
      method: () => handleShowForm(getFormSetting('OpenAccount', 'Taboola')),
    },
    {
      title: 'Yahoo關鍵字',
      // content:
      //   '擁有40多個內容頻道，強大的台灣在地用戶數據，以精準且獨家的解決方案幫您鎖定對的人。',
      footer: '約 7-14 工作日',
      imgSrc: accYahoo,
      platform: 'YahooMA',
      method: () => handleShowForm(getFormSetting('OpenAccount', 'YahooMA')),
    },
    {
      title: 'Line',
      // content:
      //   '涵蓋全台2100萬活躍用戶，搭配豐富受眾鎖定，精準設定宣傳對象，幫助您在有限預算中做最大規模曝光。',
      footer: '約 3-5 工作日',
      imgSrc: accLine,
      platform: 'Line',
      method: () => handleShowForm(getFormSetting('OpenAccount', 'Line')),
    },
    {
      title: 'TTD',
      // content:
      //   '運用豐富且客觀的數據資料庫，為您打通多元渠道，做到全方位的數位行銷策略。',
      footer: '約 1-3 工作日',
      imgSrc: accTTD,
      platform: 'TTD',
      method: () => handleShowForm(getFormSetting('OpenAccount', 'TTD')),
    },
    {
      title: 'Criteo',
      // content:
      //   '專注「電商」領域，透過全球19億的跨裝置用戶龐大消費資料，進行 AI 數據分析的「命中式」投放廣告。',
      footer: '約 5-7 工作日',
      imgSrc: accCriteo,
      platform: 'Criteo',
      method: () => handleShowForm(getFormSetting('OpenAccount', 'Criteo')),
    },
    {
      title: 'Dcard',
      // content:
      //   '每月擁有2000萬不重複訪客，趨勢話題快速傳播，立即加入台灣最大社群討論平台',
      footer: '約 7-10 工作日',
      imgSrc: accTypeDcard,
      method: () => {
        // if (hasAccounts('Criteo')) return
        handleShowForm(getFormSetting('OpenAccount', 'Dcard'))
      },
    },
    {
      title: 'Tik Tok',
      // content: '10億全球月活躍用戶，運用Tiktok打響品牌行銷力。',
      footer: '約 5-7 工作日',
      imgSrc: accTypeTiktok,
      method: () => {
        // if (hasAccounts('Criteo')) return
        handleShowForm(getFormSetting('OpenAccount', 'Tiktok'))
      },
    },
  ]
  if (auth.auth.email.includes('wavenet.com.tw')) {
    accountForms.push({
      title: 'Narwhal',
      content: ' ',
      footer: '約 1-2 工作日',
      imgSrc: accTypeNarwhal,
      method: () => {
        // if (hasAccounts('Criteo')) return
        handleShowNarwhal()
      },
    })
  }

  const pageSetting = {
    account: {
      pageTitle: '開 戶 申 請',
      pageSubTitle: 'Account creation',
      pageDescription: '請 選 擇 您 想 申 請 的 廣 告 帳 戶 類 型',
      formTitle: '申 請 廣 告 帳 戶',
      formSubTitle: 'Advertising account creation',
      form: accountForms,
      name: '開戶申請',
    },
    billing: {
      pageTitle: '儲 值 申 請',
      pageSubTitle: 'Recharge for Ads account',
      pageDescription: '請 選 擇 您 想 儲 值 的 廣 告 帳 戶 類 型',
      formTitle: '儲 值 廣 告 帳 戶',
      formSubTitle: 'Recharge for Ads account',
      form: billingForms,
      name: '儲值申請',
    },
    permission: {
      pageTitle: '權 限 申 請',
      pageSubTitle: 'Apply for permission',
      pageDescription: '請 選 擇 您 想 申 請 權 限 的 帳 戶 類 型',
      formTitle: '申 請 權 限',
      formSubTitle: 'Apply for permission',
      form: permissionForms,
      name: '權限申請',
    },
  }

  useEffect(() => {
    if (platform)
      pageSetting[action].form
        .find((f) => f.platform.toLowerCase() === platform.toLowerCase())
        .method()
  }, [platform])

  const handleCloseForm = (value, specialType = '') => {
    setshowForm(false)
    if (value)
      handleShowWarn({
        title: '表 單 送 出 確 認',
        titleEng: 'Form Sending Confirmation',
        text: (
          <h5 className="text-danger py-3">
            【注意】您正在為 {auth.account.name} 提出
            {specialType || pageSetting[action].name}
            需求。
            <br />
          </h5>
        ),
        warn: (
          <>
            {form.content
              .filter(
                (f) =>
                  !f.dependency ||
                  formData.content[f.dependency.name] === f.dependency.value
              )
              .map((f) =>
                formData.content[f.name] ? (
                  <p className="lh-lg" key={f.name}>{`${f.name}: ${
                    formData.content[f.name].length > 30
                      ? `${formData.content[f.name].substring(0, 30)}...`
                      : formData.content[f.name]
                  }`}</p>
                ) : (
                  <p />
                )
              )}
          </>
        ),
        handleClose: handleWarnClose,
      })
  }

  return (
    <Container fluid className="px-0">
      <div className="d-flex w-100 h-100">
        <Col xs={12} className="pe-1">
          <Card
            className="px-4 pb-4 h-100 d-flex flex-column overflow-hidden"
            title="選 擇 帳 戶 類 型"
          >
            <Row className="p-4 pb-0 pageTitle">
              <h4 className="pt-4 pb-1">{pageSetting[action].pageTitle}</h4>
              <p>{pageSetting[action].pageSubTitle}</p>
              {/* <p className="pt-4 pageSubtitle">
                {pageSetting[action].pageDescription}
              </p> */}
            </Row>
            <Row
              style={{
                height: '90%',
                overflowY: 'auto',
              }}
            >
              <Carousel
                setting={{
                  items: pageSetting[action].form,
                }}
              />
            </Row>
            <FormDialog
              setting={{
                size: 'md',
                show: showForm,
                form: {
                  size: 'xl',
                  title: pageSetting[action].formTitle,
                  titleEng: pageSetting[action].formSubTitle,
                  ...form,
                },
                formData,
                handleDataChange,
                handleClose: handleCloseForm,
                hasFooter: true,
              }}
            />
            <InfoDialog show={showInfo} handleClose={handleCloseInfo} />
          </Card>
        </Col>
      </div>
      <ConfirmForm
        setting={{
          ...showWarn,
          size: 'lg',
          warning: (
            <div className="text-center">
              {showWarn.img && (
                <div className="my-3">
                  <FontAwesomeIcon
                    className="mx-auto display-1 text-luca"
                    icon={showWarn.img}
                  />
                </div>
              )}
              {showWarn.text}
              {showWarn.warn}
            </div>
          ),
          handleClose: showWarn.handleClose,
        }}
      />
      {showNarwhal && (
        <Narwhal
          setting={{
            size: 'lg',
            show: showNarwhal,
            handleClose: handleNarwhalClose,
          }}
        />
      )}
    </Container>
  )
}

Question.propTypes = {
  setting: PropTypes.string.isRequired,
}

OpenAccount.propTypes = {
  action: PropTypes.string.isRequired,
}

export default OpenAccount
